body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#signinmodal{
    position: absolute;
}

div.text-setting{
    line-height: 1.5;
    color: #212529;
    text-align: left;
    padding: 2rem;
}

div.scroll-wrapper{
    border: 'none';
    border: 1px solid #CED4DA;
    border-radius: 8px;
    
}

.text-color-blue{
    color: #053381;
}


.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent; }
    .owl-theme .owl-dots .owl-dot {
      display: inline-block;
      zoom: 1;
      display: inline; }
      .owl-theme .owl-dots .owl-dot span {
        width: 20px;
        height: 20px;
        margin: 5px 7px;
        background: #e7a59f;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px; }
      .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
        background: #e33324; }
    
.owl-nav span {
    color: rgb(88, 85, 85);
	margin: 0px 0px;
	padding: 6px 19px;
	font-size: 24px;
	border-radius: 40px;
	background: #ffffff;
  border: 1px solid #a6a6a6;
	cursor: pointer;
}

.owl-nav span:hover {
    background-color: #fcc0c0;
  }


.owl-carousel .owl-nav .owl-next{
    right: -30px;
    top: calc(50% - 15px);
    -webkit-transform: translateY(-90%);
            transform: translateY(-90%);
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.owl-carousel .owl-nav .owl-prev{
    left: -30px;
    top: calc(50% - 15px);
    -webkit-transform: translateY(-90%);
            transform: translateY(-90%);
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.owl-carousel .owl-nav button.owl-prev:hover ,
.owl-carousel .owl-nav button.owl-next:hover {
    background-color: hsla(0, 0%, 100%, 0);
}


select {
  padding: 1px 18px;
  cursor: pointer;
}


/* p wut  Custom */

.utf-post-content-area{
  padding: 15px;
}
.widget-tabs li {
  padding: 0 0 10px 0;
  margin: 10px 0 0 0;
}
.utf-post-content-area h3 a {
  color: #333;
  font-family: 'KANIT-LIGHT';
  font-weight: 600;
  font-size: 17px;
} 
.utf-photo-text-content {
z-index: inherit;
}
.inner-map-search-block .utf-main-search-form-item {
margin-top: -150px;
}
.utf-parallax-content-area{
padding: 110px 0;
}
.utf-plan-features {
margin-bottom: 20px;
padding: 0.5px;
}
.utf-plan-features ul li{
padding: 4px;
font-size: 16px;
border-top: none;
}
.utf-plan-features ul li:last-child{
border-bottom:none;
}


.plan.featured .utf-listing-badges-item .featured {
background: #e33324;
  color: #ffffff;
}

.box-img-center{
  position: absolute;
  /* margin-top: 85%; */
  left: 10%;
  color: #fff;
  background: rgba(128, 82, 82, 0.15);
  border-radius: 40px;
  font-size: 12px;
  white-space: nowrap;
  width: 210px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.text-warp{
  white-space: nowrap;
  /* width: 230px;  */
  overflow: hidden;
  text-overflow: ellipsis; 
  width: -webkit-fill-available;
}

.owl-stage{
  margin-bottom: 1%;
}

.utf-listing-user-info{
  color: #909090;
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    border-top: 1px dashed rgb(242, 242, 242);
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  background: none;
    color: inherit;
    border: none;
    padding: 1px !important;
    font: inherit;
}

.compact .utf-listing-img-content-item span{
    color: #fff;
    font-size: 17px;
    font-weight: 100;
}
 
.utf-smt-listing-img-container:after, .utf-smt-listing-img-container:before{
  /* position: inherit; */
  opacity: 0.4;
}

.utf-listing-img-content-item img.utf-user-picture{
  top: -40px;
}

.compact .utf-listing-img-content-item .utf-listing-compact-title-item{
  padding: 10px;
  margin-top:20px;
}


.text-warp-title{
  /* width: 5em;  */
    /* border: 1px solid #000000; */
    word-wrap: break-word;
    /* overflow: hidden; */
    text-overflow: ellipsis; 
    width: -webkit-fill-available;
}


.error{
  background-color: red;
  color: white;
}

 
.banner-section {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto 50px;
}
.banner-section .link-block.main {
  height: 325px;
}
.banner-section .link-block {
  position: relative;
  display: block;
}

.banner-section .link-block.main img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.banner-section .initial-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
}
.banner-section .table {
  padding: 15px;
}

.banner-section .initial-wrapper h3 {
   
  font-size: 22px!important;
  line-height: 28px!important;
  color: #2E3092;
  margin: 0;
  word-break: break-all;
}
#main-wrapper a, #maincontainer-wrapper a, .modal a {
  font-size: 25px;
  line-height: 25px;
}

.banner-section .initial-wrapper .btn-link {
  position: absolute;
  left: 15px;
  bottom: 15px;
}


.banner-section .initial-wrapper .btn-link a {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  background-color: #2E3092;
   font-size: 18px;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}


.flex-col{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.flex-col2{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  align-content: flex-end;
}

@media screen and (min-width: 992px) {
  .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
  }
}

.banner-section .link-block.sub img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.banner-section .link-block img {
  width: 100%;
}
 



.wrapper {
  /* max-width: 940px; */
  max-width:  100%;
  margin: 0 20px;
  display: grid;
  grid-gap: 10px;
}

@media screen and (max-width: 500px) {
  .content {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 500px) {

  /* no grid support? */
  .sidebar {
    /* float: left; */
    width: 100%;
  }

  .image-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .image-column-30 {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 5px;
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
  }
  
  .image-column-70 {
    flex: 0 0 70%;
    max-width: 70%;
    padding: 5px;
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
  }
  
  .image-column-30 img,
  .image-column-70 img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    object-fit: cover; /* Ensures the image covers the entire area */
  }
  
  /* Responsive layout for smaller screens */
  @media (max-width: 600px) {
    .image-column-30, .image-column-70 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .wrapper {
    margin: 0 auto;
    grid-template-columns: 1fr 3fr;
  }
  
  .header, .footer {
    grid-column: 1 / -1;
    /* needed for the floated layout */
    clear: both;
  }

}

.wrapper > * {
  /* background-color: #444; */
  color: #fff;
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
  /* needed for the floated layout*/
  margin-bottom: 10px;
}

/* We need to set the widths used on floated items back to auto, and remove the bottom margin as when we have grid we have gaps. */
@supports (display: grid) {
  .wrapper > * {
    width: auto;
    margin: 0;
  }
}
 
.modal {
  background: green;
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modals.dimmer .ui.scrolling.modal{
  margin: 0rem auto;
}

 
.ui.fullscreen.modal {
  width: 100%!important;
}

button.button-p {
  background-color: rgb(98, 180, 82);
    top: 0;
    padding: 10px 20px;
    color: #fff;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 6px;
    overflow: hidden;
    border: none;
    box-shadow: 0 1px 4px 0px rgb(0 0 0 / 5%);
    border-radius: 4px;
}



.home-icon {
  font-size: 2em;
} 


.box{
  /* background: linear-gradient( 110.3deg,  rgba(73,93,109,1) 4.3%, rgba(49,55,82,1) 96.7% ); */
  background: linear-gradient(#ef8e38,#108dc7);
  font-family: 'Raleway', sans-serif;
  text-align: center;
  border: 1px solid #313752;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease 0s;
}
.box:hover{ box-shadow: 0 0 5px rgba(0,0,0,0.4); }
.box:before{
  content: "";
  width: 100%;
  height: 100%;
  border: 5px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 0 30px #313752;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.9);
          transform: translateX(-50%) translateY(-50%) scale(0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.35s;
}
.box:hover:before{ opacity: 1; }
.box img{
  width: 100%;
  height: auto;
  opacity: 1.0;
  transition: .3s linear;
}
.box:hover img{
  opacity: 0.3;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.box .box-content{
  color: #fff;
  width: 100%;
  -webkit-transform: translateY(-30%);
          transform: translateY(-30%);
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  transition: all .5s ease;
}   
.box:hover .box-content{ -webkit-transform: translateY(-50%); transform: translateY(-50%); }
.box .title{
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 3px 0;
  opacity: 0;
  transition: all 0.5s ease 0s;
}
.box .post{
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 10px;
  display: block;
  opacity: 0;
  transition: all 0.5s ease 0s;
}
.box:hover .title,
.box:hover .post{
  opacity: 1;
  transition-delay: 0.2s;
}
.box .icon{
  padding: 0;
  margin: 0;
  list-style: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.5s ease;
}
.box:hover .icon{ -webkit-transform: scale(1); transform: scale(1); }
.box .icon li{
  margin: 0 2px;
  display: inline-block;
}
.box .icon li a{
  color: #313752;
  background-color: #fff;
  font-size: 16px;
  line-height: 35px;
  height: 35px;
  width: 35px;
  display: block;
  transition: all 0.5s ease 0s;
}
.box .icon li a:hover{
  color: #fff;
  background-color: #313752;
  box-shadow: 0 0 5px rgba(0,0,0,0.8);
}
.box .txt{
  font-size: 12px;
  margin-top: 5px;
}
@media only screen and (max-width:990px){
  .box{ margin: 0 0 30px; }
}


.box-detail {
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	background-color: #fff;
	display: inline-block;
	width: 100%;
	border-radius: 3px;
	 
}
.box-detail .txt{
  padding: 5px 10px 5px 10px;
}

.btn-link {
  /* font-weight: 400; */
  color: #007bff;
  background-color: transparent;
  }
  
  .btn-right{
    background-color: rgb(217, 34, 40);
    border: none;
    padding: 3px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    left: 78.6%;
    color:#fff;
  }
  .btn-right:hover{
    color: #e33324;
    background-color: #fff;
    border:1px solid rgb(217, 34, 40);;
  }
  

.utf-post-content-area-top {
    padding: 2px;
}
.title-hot-asset{
  color: #706e6e;
  font-size: 1.2rem;
}
.detail-hot-asset{
  font-size: 1rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ditail-hot-price{
  font-size: 1.4rem;
  color: rgb(65, 62, 62);
}
 
.hover-img img{
width: 100%;
height: 148px;
Object-fit:cover;
}
.hover-img:hover img{
  opacity: 0.7;
}
 
.tabs-nav li a:hover, .tabs-nav li.active a{
color: #09192f;
z-index: 11;
border-bottom: 2px solid #e33324;
border-radius: 4px 4px 0 0;
background: none;
}

.section-hotnew{
  padding: 20px 0px;
}
.section-hotnew .col-md-9 .col-sm-12 {
  padding: 0px;
}
.hot-new-title {
  display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
 

 
/* test */

.grid-con{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(2,250px);
}
.grid-con img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  .grid-item-1{
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  overflow: hidden;
}
.grid-item-2{
  display: grid;
  grid-template-rows: 250px;
  grid-template-columns: repeat(2,1fr);
  grid-column:  3 / 5;
  grid-row: 1 / 2;
  overflow: hidden;
}
.grid-item-3{
  display: grid;
  grid-template-rows: 250px;
  grid-template-columns: repeat(2,1fr);
  grid-column: 3 / 5;
  grid-row:  2 / 3;
  overflow: hidden;
}   
/* .grid-item-4 {
  display: grid;
  grid-template-rows: 330px;
  grid-template-columns: repeat(2,1fr);
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  overflow: hidden;
}
.grid-item-5{
  grid-column: 3 / 5;
  grid-row: 3 / 4 ;
  overflow: hidden;
} */
  .grid-item-info{
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-item-info p {
  margin: 1rem 0rem;
  font-size: 1.6rem;
}
.grid-item-info .black-btn {
  display: inline-block;
  background-color: rgb(93 113 110);
  color:#fff;
  text-decoration: none;
  padding: 0.5rem 3rem;
  border-bottom: 4px solid rgb(30 72 66);
}  
@media (max-width: 820px) {
  .grid-con{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(3,250px);
  }
  .grid-item-1{
    grid-column: 1 / 5;
    grid-row: 1;
    overflow: hidden;
    grid-gap: 2rem;
  }
  .grid-item-2{
    display: grid;
    grid-template-rows: 250px;
    grid-template-columns: repeat(2,1fr);
    grid-column:  1 / 5;
    grid-row: 2 / 2;
    overflow: hidden;
  }
  .grid-item-3{
    display: grid;
    grid-template-rows: 250px;
    grid-template-columns: repeat(2,1fr);
    grid-column: 1 / 5;
    grid-row:  3 / 3;
    overflow: hidden;
  }  
  
  .grid-item-info{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-item-info p {
    margin: 1rem 0rem;
    font-size: 1rem;
    line-height: 2rem;
  }
  .grid-item-info h3{
    display: inline-block;
    margin-top: 0px;
  }
  .grid-item-info .black-btn {
    display: inline-block;
    background-color: rgb(93 113 110);
    color:#fff;
    text-decoration: none;
    padding: 0.5rem 3rem;
    border-bottom: 4px solid rgb(30 72 66);
    font-size: 1.3rem; 
  }
}
/* .button-icon {
    background-color: rgba(255, 255, 255, 0.149);
    border-radius: 4px;
    height: 46px;
    padding: 0 18px;
    text-align: center;
    width: auto;
	margin-left:3px;
	margin-right:3px;
	margin-bottom:5px;
	display:inline-block;
	transition: 0.3s;
} */

.search-container .row.with-forms .chosen-container {
	margin-bottom: 0
}
.search-container .chosen-container-single .chosen-single {
	height: 45px;
	line-height: 45px;
	font-size: 16px;
	border-color: #fff !important
}
.search-container .chosen-container .chosen-drop {
	border-color: #fff
}

.row.with-forms input, .row.with-forms input[type="text"], .row.with-forms input[type="password"], .row.with-forms input[type="email"], .row.with-forms input[type="number"], .row.with-forms textarea, .row.with-forms input, .row.with-forms select, .row.with-forms .chosen-container {
	margin-bottom: 18px;
}

.submit-page .chosen-container.chosen-container-single{
	margin-bottom:15px;
}

/* ---------------------------------- */
/* Chosen Plugin
------------------------------------- */
.chosen-container {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	font-size: 15px;
	zoom: 1;
 *display: inline;
	-webkit-user-select: none;
	-ms-user-select: none;
	    user-select: none;
}
.chosen-container * {
	box-sizing: border-box;
}
.chosen-container .chosen-drop {
	position: absolute;
	top: 100%;
	left: -9999px;
	z-index: 990;
	width: 100%;
	background-color: #fbfdff;
    border: 1px solid #dde6ef;
	background: linear-gradient(to bottom, rgba(251,253,255,1) 0%, rgba(251,253,255,1) 100%);
	border-top: 0;
	border-radius: 0 0 3px 3px;
}
.chosen-container.chosen-with-drop .chosen-drop {
	left: 0;
}
.chosen-container a {
	cursor: pointer;
}
.chosen-container-single .chosen-single {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0 0 0 18px;
	height: 45px;
	line-height: 45px;
	background-color: #fbfdff;
    border: 1px solid #dde6ef;
	box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
	color: #888;
	text-decoration: none;
	white-space: nowrap;
	font-weight: 500;
	border-radius: 3px;
}
.chosen-container-single .chosen-default {
	color: #999;
}
.chosen-container-single .chosen-single span {
	display: block;
	overflow: hidden;
	margin-right: 26px;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #888;
}
.chosen-container-single .chosen-single-with-deselect span {
	margin-right: 38px;
}
.chosen-container-single .chosen-single div {
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	width: 18px;
	height: 100%;
}
.chosen-container-single .chosen-single div b {
	display: block;
	position: relative;
}
.chosen-container-single .chosen-single div b:after {
	content: "\f0d7";
	font-family: "FontAwesome";
	font-size: 14px;
	margin: 0 14px 0 0;
	top: -1px;
	position: relative;
	width: 11px;
	height: 5px;
	display: inline-block;
	color: #c0c0c0;
	float: right;
	font-weight: normal;
}
.chosen-container-single .chosen-search {
	position: relative;
	z-index: 1010;
	margin: 0;
	padding: 3px 8px;
	white-space: nowrap;
}
.chosen-container-single .chosen-search input[type="text"] {
	margin: 1px 0;
	padding: 6px 20px 6px 6px;
	width: 100%;
	height: 40px;
	outline: 0;
	border: 1px solid #ddd;
	background-color: #fff;
	display: block;
	position: relative;
	z-index: 9;
	background-color: #fafafa;
}
.chosen-container-single .chosen-search:before {
	content: "\f002";
	font-family: "FontAwesome";
	font-size: 15px;
	margin: 0 15px 0 0;
	top: 12px;
	position: relative;
	width: 11px;
	height: 5px;
	display: inline-block;
	color: #aaa;
	float: right;
	font-weight: normal;
	z-index: 10;
}
.chosen-container-single .chosen-drop {
	margin-top: -1px;
	background-clip: padding-box;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
	position: absolute;
	left: -9999px;
}
.utf-sort-box-aera {
    display: inline-block;
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    padding: 12px 15px 2px 15px;
    position: relative;
    line-height: 28px;
    margin-bottom:20px;
}
.sort-by {
	position: relative;
	top: 0px;
	float:left;
}
.sort-by label, .sort-by .sort-by-select {
	display: inline-block;
	float: left;
}
.sort-by label {
	line-height: 40px;
	margin-right: 8px;
	padding: 0;
	margin-bottom:0;
}
.sort-by .chosen-container-active .chosen-single, .sort-by .chosen-container-active .chosen-choices, .sort-by .chosen-container-active.chosen-with-drop .chosen-single, .sort-by .chosen-container-single .chosen-single {
	height: 40px;
	line-height: 40px;
	border: 1px solid #f2f2f2;
	background-color: #fff;
	width: 100%;
	display: inline-block;
	padding-left: 10px;
}
.sort-by .chosen-container-active .chosen-drop, .sort-by .chosen-container-single .chosen-drop {
	opacity: 0;
	transition: opacity 0.3s;
}
.sort-by .chosen-container-active.chosen-with-drop .chosen-drop {
	opacity: 1;
}
.sort-by .chosen-container .chosen-drop {
	padding-top: 10px;
	border: 1px solid #e0e0e0;
	border-top: 1px solid #e0e0e0;
	border-radius: 3px;
	width: 200px;
	margin-left: 0px;
	background: #fff;
	box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
	z-index: 999;
}
.sort-by .chosen-container-single .chosen-single div b:after {
	color: #e33324;
}
.sort-by .chosen-container-single .chosen-default {
	color: #e33324;
}
.sort-by .chosen-container-single .chosen-single span {
	margin-right: 0px;
	color: #707070;
	font-weight:500;
	vertical-align: top;
}
.sort-by .chosen-container-single .chosen-single div {
	position: relative;
	top: -1px;
	margin-left: 15px;
	display: inline-block;
}
.chosen-container-single .chosen-single span {
	display: inline-block;
	margin-right: 0;
}
.chosen-container .chosen-results {
	color: #888;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0 4px 8px 0;
	padding: 0 4px 0 8px;
	max-height: 300px;
}
.chosen-container .chosen-results li {
	display: none;
	margin: 0;
	/*border-top:1px solid #dde6ef;*/
	padding: 10px 10px;
	list-style: none;
	line-height: 15px;
	word-wrap: break-word;
	-webkit-touch-callout: none;
}
.chosen-container-multi .chosen-results li {
	padding: 10px 18px;
}
.chosen-container .chosen-results li.active-result {
	display: list-item;
	cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
	display: list-item;
	color: #ccc;
	cursor: default;
}
.chosen-container .chosen-results li.highlighted {
	background-color: #e33324;
	border-radius: 3px;
	color: #fff;
	transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.chosen-container .chosen-results li.no-results {
	color: #888;
	display: list-item;
	background-color: #fff;
}
.chosen-container-multi .chosen-results li.no-results {
	background-color: #fff;
}
.chosen-container .chosen-results li.group-result {
	display: list-item;
	font-weight: bold;
	cursor: default;
}
.chosen-container .chosen-results li.group-option {
	padding-left: 15px;
}
.chosen-container .chosen-results li em {
	font-style: normal;
	text-decoration: underline;
}
.chosen-container-multi .chosen-choices {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0 0 0 18px;
	height: 53px;
	border: 1px solid #e0e0e0;
	background-color: #fcfcfc;
	text-decoration: none;
	white-space: nowrap;
	line-height: 33px;
	cursor: pointer;
	margin: 0;
}
.chosen-container-multi .chosen-choices li {
	float: left;
	list-style: none;
}
.chosen-container-multi .chosen-choices li.search-field {
	margin: 0;
	padding: 0;
	white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
	margin: 0;
	padding: 0;
	height: 53px;
	outline: 0;
	border: 0 !important;
	background: transparent !important;
	color: #888;
	line-height: normal;
	font-weight: 500;
}
.chosen-container-multi .chosen-choices li.search-choice {
	position: relative;
	margin: 12px 5px 0 -4px;
	padding: 0 25px 0 8px;
	max-width: 100%;
	background-color: #e33324;
	line-height: 29px;
	color: #fff;
	cursor: default;
	font-weight: 500;
}
.chosen-container-multi .chosen-choices li.search-choice span {
	word-wrap: break-word;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
	position: absolute;
	top: -2px;
	right: 7px;
	display: block;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
	content: "\f00d";
	font-family: "FontAwesome";
	font-size: 13px;
	top: 1px;
	position: relative;
	width: 11px;
	height: 5px;
	display: inline-block;
	color: #fff;
	float: right;
	font-weight: normal;
}
.chosen-container-multi .chosen-choices li.search-choice-disabled {
	padding-right: 5px;
	border: 1px solid #ccc;
	background-color: #e4e4e4;
	color: #888;
}
.chosen-container-multi .chosen-choices li.search-choice-focus {
	background: #d4d4d4;
}
.chosen-container-multi .chosen-results {
	margin: 0;
	padding: 0;
}
.chosen-container-multi .chosen-drop .result-selected {
	display: list-item;
	color: #ccc;
	cursor: default;
}
.chosen-container-active .chosen-single, .chosen-container-active .chosen-choices, .chosen-container-active.chosen-with-drop .chosen-single {
	border: 1px solid #e0e0e0;
}
.chosen-container-active.chosen-with-drop .chosen-single div {
	border-left: none;
	background: transparent;
}
.chosen-container-active.chosen-with-drop .chosen-single div b:after {
	content: "\f0d8"
}
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
	color: #888 !important;
}
.chosen-drop ::-webkit-scrollbar {
width: 10px;
}
 .chosen-drop ::-webkit-scrollbar-track {
 background-color: rgba(0,0,0,0.05);
 border-radius: 10px;
}
 .chosen-drop ::-webkit-scrollbar-thumb {
 border-radius: 10px;
 background-color: rgba(0,0,0,0.2);
}
 .chosen-drop ::-webkit-scrollbar-thumb:hover {
 border-radius: 10px;
 background-color: rgba(0,0,0,0.3);
}



/* เพ่ิมใหม่ */

.Boxhead{
    text-align: center;
}
#titlebar{
    margin-bottom: 20px;
}
h2  {
    font-family: Kanit-light;
    font-size: 30px;
    font-weight: 500;
  }
   
.hrstyle{
    margin-left: 100px;
    margin-right: 100px;
    border-bottom: 1px dashed #f2f2f2;
}
.price {
font-size: 35px;
color:#14d364;
font-weight: 500;
text-shadow: 2px 2px 3px rgba(255,255,255,0.1);
 }
.txtproce{
    margin-top: 10px;
}
.imag_chart{
    text-align: center;
}
.txt_tab{
    margin-left: 30px;
}
.txt_tab_bold{
    margin-left: 30px;
    font-weight: 600;
}
.checkboxes.in-row label{
    width: 80%;
}
input:read-only {
    background-color: #f5f5f5;
  }
 
.utf-sidebar-widget-item {
    /* margin-top: 20px; */
    margin-bottom: 10px;
    display: block;
    background: #ffffff;
    padding:0px;
    border-radius: 0px;
    border:none;
    box-shadow:none;
}
 
.utf-sidebar-widget-item {
  padding: 0px;
}
input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
select {
  margin: 5px 0px;
}

table.manage-table .utf-title-container .title h4 a {
  font-family: "Kanit-Light";
  font-size: 16px;
}
table.manage-table .utf-title-container .title span {
  display: block;
  font-family: "Kanit-Light";
  font-size: 14px;
}
select {
  padding: 10px 18px;
  cursor: pointer;
}
.utf-sidebar-widget-item {
  border: none;
  box-shadow: none;
}
.checkbox label {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 25px;
}
.utf-expire-date{
  font-size:13px;
}
/* .utf-search-type-block-area { 
  margin-top: 30px;
}
.utf-listing-title h4{
  text-align:left;
}  */

 
.leaflet-container {
    height: 400px;
    width: 100%;
}

.h-line{
    padding: 5px 0px 10px 0px;
    margin-bottom: 25px;
    display: block;    
     border-bottom: 1px dashed #868585;
    margin-top: 40px;  
    background-color: none;
    color:#868585;
}

.property-features{
    width:'70%';
    margin: 10px 0px 0px 0px;
}
 
 #mapid { height: 1000px; }

h3  {
    font-family: Kanit-light;
    font-size: 20px;
  }
   
   body{
    font-family: Kanit-light;
   }



   a{text-decoration:none}
h4{text-align:left;color:#444}
.main-timeline{position:relative}
.main-timeline:before{content:"";width:5px;height:100%;border-radius:20px;margin:0 auto;background:#242922;position:absolute;top:0;left:0;right:0}
.main-timeline .timeline{display:inline-block;margin-bottom:50px;position:relative}
.main-timeline .timeline:before{content:"";width:20px;height:20px;border-radius:50%;border:4px solid #fff;background:#ec496e;position:absolute;top:50%;left:50%;z-index:1;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}
.main-timeline .timeline-icon{display:inline-block;width:130px;height:130px;border-radius:50%;border:3px solid #ec496e;padding:13px;text-align:center;position:absolute;top:50%;left:30%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}
.main-timeline .timeline-icon i{display:block;border-radius:50%;background:#ec496e;font-size:64px;color:#fff;line-height:100px;z-index:1;position:relative}
.main-timeline .timeline-icon:after,.main-timeline .timeline-icon:before{content:"";width:100px;height:4px;background:#ec496e;position:absolute;top:50%;right:-100px;-webkit-transform:translateY(-50%);transform:translateY(-50%)}
.main-timeline .timeline-icon:after{width:70px;height:50px;background:#fff;top:89px;right:-30px}
.main-timeline .timeline-content{width:50%;padding:0 50px;margin:52px 0 0;float:right;position:relative}
.main-timeline .timeline-content:before{content:"";width:70%;height:100%;border:3px solid #ec496e;border-top:none;border-right:none;position:absolute;bottom:-13px;left:35px}
.main-timeline .timeline-content:after{content:"";width:37px;height:3px;background:#ec496e;position:absolute;top:13px;left:0}
.main-timeline .title{font-size:20px;font-weight:600;color:#ec496e;text-transform:uppercase;margin:0 0 5px}
.main-timeline .description{display:inline-block;font-size:16px;color:#404040;line-height:20px;letter-spacing:1px;margin:0}
.main-timeline .timeline:nth-child(even) .timeline-icon{left:auto;right:30%}
.main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-100px}
.main-timeline .timeline:nth-child(even) .timeline-icon:after{right:auto;left:-30px}
.main-timeline .timeline:nth-child(even) .timeline-content{float:left}
.main-timeline .timeline:nth-child(even) .timeline-content:before{left:auto;right:35px;-webkit-transform:rotateY(180deg);transform:rotateY(180deg)}
.main-timeline .timeline:nth-child(even) .timeline-content:after{left:auto;right:0}
.main-timeline .timeline:nth-child(2n) .timeline-content:after,.main-timeline .timeline:nth-child(2n) .timeline-icon i,.main-timeline .timeline:nth-child(2n) .timeline-icon:before,.main-timeline .timeline:nth-child(2n):before{background:#f9850f}
.main-timeline .timeline:nth-child(2n) .timeline-icon{border-color:#f9850f}
.main-timeline .timeline:nth-child(2n) .title{color:#f9850f}
.main-timeline .timeline:nth-child(2n) .timeline-content:before{border-left-color:#f9850f;border-bottom-color:#f9850f}
.main-timeline .timeline:nth-child(3n) .timeline-content:after,.main-timeline .timeline:nth-child(3n) .timeline-icon i,.main-timeline .timeline:nth-child(3n) .timeline-icon:before,.main-timeline .timeline:nth-child(3n):before{background:#8fb800}
.main-timeline .timeline:nth-child(3n) .timeline-icon{border-color:#8fb800}
.main-timeline .timeline:nth-child(3n) .title{color:#8fb800}
.main-timeline .timeline:nth-child(3n) .timeline-content:before{border-left-color:#8fb800;border-bottom-color:#8fb800}
.main-timeline .timeline:nth-child(4n) .timeline-content:after,.main-timeline .timeline:nth-child(4n) .timeline-icon i,.main-timeline .timeline:nth-child(4n) .timeline-icon:before,.main-timeline .timeline:nth-child(4n):before{background:#2fcea5}
.main-timeline .timeline:nth-child(4n) .timeline-icon{border-color:#2fcea5}
.main-timeline .timeline:nth-child(4n) .title{color:#2fcea5}
.main-timeline .timeline:nth-child(4n) .timeline-content:before{border-left-color:#2fcea5;border-bottom-color:#2fcea5}
@media only screen and (max-width:1200px){.main-timeline .timeline-icon:before{width:50px;right:-50px}
.main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-50px}
.main-timeline .timeline-content{margin-top:75px}
}
@media only screen and (max-width:990px){.main-timeline .timeline{margin:0 0 10px}
.main-timeline .timeline-icon{left:25%}
.main-timeline .timeline:nth-child(even) .timeline-icon{right:25%}
.main-timeline .timeline-content{margin-top:115px}
}
@media only screen and (max-width:767px){.main-timeline{padding-top:50px}
.main-timeline:before{left:80px;right:0;margin:0}
.main-timeline .timeline{margin-bottom:70px}
.main-timeline .timeline:before{top:0;left:83px;right:0;margin:0}
.main-timeline .timeline-icon{width:60px;height:60px;line-height:40px;padding:5px;top:0;left:0}
.main-timeline .timeline:nth-child(even) .timeline-icon{left:0;right:auto}
.main-timeline .timeline-icon:before,.main-timeline .timeline:nth-child(even) .timeline-icon:before{width:25px;left:auto;right:-25px}
.main-timeline .timeline-icon:after,.main-timeline .timeline:nth-child(even) .timeline-icon:after{width:25px;height:30px;top:44px;left:auto;right:-5px}
.main-timeline .timeline-icon i{font-size:30px;line-height:45px}
.main-timeline .timeline-content,.main-timeline .timeline:nth-child(even) .timeline-content{width:100%;margin-top:-15px;padding-left:130px;padding-right:5px}
.main-timeline .timeline:nth-child(even) .timeline-content{float:right}
.main-timeline .timeline-content:before,.main-timeline .timeline:nth-child(even) .timeline-content:before{width:50%;left:120px}
.main-timeline .timeline:nth-child(even) .timeline-content:before{right:auto;-webkit-transform:rotateY(0);transform:rotateY(0)}
.main-timeline .timeline-content:after,.main-timeline .timeline:nth-child(even) .timeline-content:after{left:85px}
}
@media only screen and (max-width:479px){.main-timeline .timeline-content,.main-timeline .timeline:nth-child(2n) .timeline-content{padding-left:110px}
.main-timeline .timeline-content:before,.main-timeline .timeline:nth-child(2n) .timeline-content:before{left:99px}
.main-timeline .timeline-content:after,.main-timeline .timeline:nth-child(2n) .timeline-content:after{left:65px}
}



/******************* Timeline Demo - 4 *****************/
.main-timeline4{overflow:hidden;position:relative}
.main-timeline4:before{content:"";width:5px;height:70%;background:#333;position:absolute;top:70px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}
.main-timeline4 .timeline-content:before,.main-timeline4 .timeline:before{top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);content:""}
.main-timeline4 .timeline{width:50%;padding-left:100px;float:right;position:relative}
.main-timeline4 .timeline:before{width:20px;height:20px;border-radius:50%;background:#fff;border:5px solid #333;position:absolute;left:-10px}
.main-timeline4 .timeline-content{display:block;padding-left:150px;position:relative}
.main-timeline4 .timeline-content:before{width:90px;height:10px;border-top:7px dotted #333;position:absolute;left:-92px}
.main-timeline4 .year{display:inline-block;width:120px;height:120px;line-height:100px;border-radius:50%;border:10px solid #f54957;font-size:30px;color:#f54957;text-align:center;box-shadow:inset 0 0 10px rgba(0,0,0,.4);position:absolute;top:0;left:0}
.main-timeline4 .year:before{content:"";border-left:20px solid #f54957;border-top:10px solid transparent;border-bottom:10px solid transparent;position:absolute;bottom:-13px;right:0;-webkit-transform:rotate(45deg);transform:rotate(45deg)}
.main-timeline4 .inner-content{padding:20px 0}
.main-timeline4 .title{font-size:24px;font-weight:600;color:#f54957;text-transform:uppercase;margin:0 0 5px}
.main-timeline4 .description{font-size:14px;color:#6f6f6f;margin:0 0 5px;padding:20px;}
.main-timeline4 .timeline:nth-child(2n){padding:0 100px 0 0}
.main-timeline4 .timeline:nth-child(2n) .timeline-content:before,.main-timeline4 .timeline:nth-child(2n) .year,.main-timeline4 .timeline:nth-child(2n):before{left:auto;right:-10px}
.main-timeline4 .timeline:nth-child(2n) .timeline-content{padding:0 150px 0 0}
.main-timeline4 .timeline:nth-child(2n) .timeline-content:before{right:-92px}
.main-timeline4 .timeline:nth-child(2n) .year{right:0}
.main-timeline4 .timeline:nth-child(2n) .year:before{right:auto;left:0;border-left:none;border-right:20px solid #f54957;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.main-timeline4 .timeline:nth-child(2){margin-top:110px}
.main-timeline4 .timeline:nth-child(odd){margin:-110px 0 0}
.main-timeline4 .timeline:nth-child(even){margin-bottom:80px}
.main-timeline4 .timeline:first-child,.main-timeline4 .timeline:last-child:nth-child(even){margin:0}
.main-timeline4 .timeline:nth-child(2n) .year{border-color:#1ebad0;color:#1ebad0}
.main-timeline4 .timeline:nth-child(2) .year:before{border-right-color:#1ebad0}
.main-timeline4 .timeline:nth-child(2n) .title{color:#1ebad0}
.main-timeline4 .timeline:nth-child(3n) .year{border-color:#7cba01;color:#7cba01}
.main-timeline4 .timeline:nth-child(3) .year:before{border-left-color:#7cba01}
.main-timeline4 .timeline:nth-child(3n) .title{color:#7cba01}
.main-timeline4 .timeline:nth-child(4n) .year{border-color:#f8781f;color:#f8781f}
.main-timeline4 .timeline:nth-child(4) .year:before{border-right-color:#f8781f}
.main-timeline4 .timeline:nth-child(4n) .title{color:#f8781f}
@media only screen and (max-width:1200px){.main-timeline4 .year{top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}
}
@media only screen and (max-width:990px){.main-timeline4 .timeline{padding-left:75px}
.main-timeline4 .timeline:nth-child(2n){padding:0 75px 0 0}
.main-timeline4 .timeline-content{padding-left:130px}
.main-timeline4 .timeline:nth-child(2n) .timeline-content{padding:0 130px 0 0}
.main-timeline4 .timeline-content:before{width:68px;left:-68px}
.main-timeline4 .timeline:nth-child(2n) .timeline-content:before{right:-68px}
}
@media only screen and (max-width:767px){.main-timeline4{overflow:visible}
.main-timeline4:before{height:100%;top:0;left:0;-webkit-transform:translateX(0);transform:translateX(0)}
.main-timeline4 .timeline:before,.main-timeline4 .timeline:nth-child(2n):before{top:60px;left:-9px;-webkit-transform:translateX(0);transform:translateX(0)}
.main-timeline4 .timeline,.main-timeline4 .timeline:nth-child(even),.main-timeline4 .timeline:nth-child(odd){width:100%;float:none;text-align:center;padding:0;margin:0 0 10px}
.main-timeline4 .timeline-content,.main-timeline4 .timeline:nth-child(2n) .timeline-content{padding:0}
.main-timeline4 .timeline-content:before,.main-timeline4 .timeline:nth-child(2n) .timeline-content:before{display:none}
.main-timeline4 .timeline:nth-child(2n) .year,.main-timeline4 .year{position:relative;-webkit-transform:translateY(0);transform:translateY(0)}
.main-timeline4 .timeline:nth-child(2n) .year:before,.main-timeline4 .year:before{border:none;border-right:20px solid #f54957;border-top:10px solid transparent;border-bottom:10px solid transparent;top:50%;left:-23px;bottom:auto;right:auto;-webkit-transform:rotate(0);transform:rotate(0)}
.main-timeline4 .timeline:nth-child(2n) .year:before{border-right-color:#1ebad0}
.main-timeline4 .timeline:nth-child(3n) .year:before{border-right-color:#7cba01}
.main-timeline4 .timeline:nth-child(4n) .year:before{border-right-color:#f8781f}
.main-timeline4 .inner-content{padding:10px}
}




.utf-icon-box-item-area{
    margin-bottom: 30px;
    padding: 10px 10px;
}
.utf-icon-box-item-area:hover{
 background-color:rgb(197, 190, 190);
 color:#fff;
}
.blog-img-new{
    width: 100%;
    height: 100%;
}

#utf-compare-list li div {
    flex: 1 1;
    border-right: 1px solid transparent;
    padding: 10px 0px;
    color: #777;
    text-align: center;
    border-right: 1px dashed;
    border-bottom: 1px dashed;
}
#utf-compare-list li {
    border-bottom: 1px solid transparent;
    font-size: 15px;
}
.txt-left{
    text-align: left;
}
th{
    text-align: center;
    vertical-align: middle;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    vertical-align: middle;
    font-size: 15px;
}

 
  
  /* try removing the "hack" below to see how the table overflows the .body */
  .hack1 {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  
  .hack2 {
    display: table-cell;
    overflow-x: auto;
    width: 100%;
  }
  .sl-icon{  
    background-color: beige;
    padding: 20px;
    border-radius: 30px;
   }
 

  .custom-menu ul li{
    margin: 5px;
}
.login{
    background-color: #ff6e6e;
    border-radius: 5px;
    margin: 5px;
    padding: 0 5px;
}
.signup{
    background-color: #64cbff;
    border-radius: 5px;
    margin: 5px;
    padding: 0 5px;
}
.signup a, .login a{
    color: #fff !important;
}
.bg{
    background-image: url(https://i.postimg.cc/3NDp9bvc/background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 50px;;
}
.count div{
    padding: 20px;
}
.count{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.form{
    margin-top: 20px;;
}
.form input{
    margin-top: 10px;;
    width: 60%;
    margin-left: 20%;
    padding: 5px 15px;
    border-radius: 20px;
    border: none;
    background-color: #c3d7e2;
    outline: none;
}
.form button{
    width: 30%;
    border: none;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #dd4c54;
    color: #fff;
    outline: none;
    cursor: pointer;
}
 .fc-event-main {
    white-space: normal;
}

.fc-daygrid-event{
    white-space: normal;
    background-color: lightgray;
    border-color: gray;
}

.fc .fc-daygrid-event i{
    color: #fff
}

.fc th a{
    color: #fff
}

.fc th .fc-day-sun
,.fc th .fc-day-sat {
    background-color: #e33324
}

.fc th .fc-day-mon
,.fc th .fc-day-tue
,.fc th .fc-day-wed
,.fc th .fc-day-thu
,.fc th .fc-day-fri {
    background-color: #8a8d92
}

.fc .fc-button-primary{
    background-color: #8a8d92;
    border-color: gray;
}

.fc .fc-button-primary:hover{
    background-color: #e33324;
    border-color: gray
}

.fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #e33424;
    border-color: gray;
}

.fc .fc-toolbar-title{
    color: #e33424; 
    font-weight: 600;
} 

i {
    font-style: normal;
    font-size: medium;
    vertical-align: middle;
}
.bullet{
    font-style: normal;
    font-size: 30px;
    vertical-align: middle;
}
.fc-day-today {
    background: #53aaec !important;
    border: none !important;
  
} 
.title-number{
    font-weight: bold;
    font-size:20px;
    color:#000000;
}

/* input, input[type=text], input[type=password], input[type=email], input[type=number], textarea, select{
    border-bottom: 1px dashed #5555;
    background-color: #fff;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    box-shadow: none;
    
}
input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], select{
    padding: 0 0px;
} */

.box-profile {
    border: 1px solid #dfd6d6;
    padding: 20px;
    /* box-shadow: 5px 10px 8px #888888; */
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    display: block;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    margin-bottom: 30px;
    position: inherit;
  }
.txtValidat {
    color: red;
    font-size: 14px;
    margin-bottom: -5px;
}
  
input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], select {
    margin: 5px 0px;
  }
  .tab-content {
  padding: 0px 0px;
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 100%;
}
.utf-sidebar-widget-item {
  padding: 0px;
  margin-bottom: 0px;
  display: block;
  background: #ffffff;
  padding: 0px;
  border-radius: 6px;
  border: 0px;
  box-shadow: none;
}
.h5, h5 {
  margin-top: 18px;
  margin-bottom: 10px;
  color: #846d6d;
  font-family: Kanit-Light;
}
.nav>li>a:focus, .nav>li>a:hover{background: transparent!important;}
.design-process-section .text-align-center {
  line-height: 25px;
  margin-bottom: 12px;
}
.design-process-content {
  border: 1px solid #e9e9e9;
  position: relative;
  padding: 16px 34% 30px 30px;
}
.design-process-content img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  max-height: 100%;
}
.design-process-content h3 {
  margin-bottom: 16px;
}
.design-process-content p {
  line-height: 26px;
  margin-bottom: 12px;
}
.process-model {
  list-style: none;
  padding: 0;
  position: relative;
  max-width: 600px;
  margin: 20px auto 26px;
  border: none;
  z-index: 0;
}
.process-model li::after {
  background: #e5e5e5 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  margin: 0 auto;
  position: absolute;
  right: -30px;
  top: 33px;
  width: 85%;
  z-index: -1;
}
.process-model li.visited::after {
  background: #D6DA28;
}
.process-model li:last-child::after {
  width: 0;
}
.process-model li {
  display: inline-block;
  width: 18%;
  text-align: center;
  float: none;
}
.nav-tabs.process-model > li.active > a, .nav-tabs.process-model > li.active > a:hover, .nav-tabs.process-model > li.active > a:focus, .process-model li a:hover, .process-model li a:focus {
  border: none;
  background: transparent;

}
.process-model li a {
  padding: 0;
  border: none;
  color: #606060;
}
.process-model li.active,
.process-model li.visited {
  color: #e33324;
}
.process-model li.active a,
.process-model li.active a:hover,
.process-model li.active a:focus,
.process-model li.visited a,
.process-model li.visited a:hover,
.process-model li.visited a:focus {
  color: #e33324;
}
.process-model li.active p,
.process-model li.visited p {
  font-weight: 100;
  color: #e33324;
  font-family: Kanit-Light;
}
.process-model li i {
  display: block;
  height: 68px;
  width: 68px;
  text-align: center;
  margin: 0 auto;
  background: #f5f6f7;
  border: 2px solid #e5e5e5;
  line-height: 65px;
  font-size: 30px;
  border-radius: 50%;
}
.process-model li.active i, .process-model li.visited i  {
  background: #fff;
  border-color: #e33324;
}
.process-model li p {
  font-family: Kanit-light;
  font-size: 14px;
  margin-top: 11px;
}
.process-model.contact-us-tab li.visited a, .process-model.contact-us-tab li.visited p {
  color: #606060!important;
  font-weight: normal
}
.process-model.contact-us-tab li::after  {
  display: none; 
}
.process-model.contact-us-tab li.visited i {
  border-color: #e5e5e5; 
}

.header-inbox{
  font-family:Kanit-light;padding: 1px 0px 10px 0px;margin: 20px 20px 10px 14px;border-bottom: 1px dashed #cbcdd0;
  font-weight: 500;
}

.checkboxes.in-row label {
font-weight: 100;
}
@media screen and (max-width: 560px) {
.more-icon-preocess.process-model li span {
      font-size: 23px;
      height: 50px;
      line-height: 46px;
      width: 50px;
  }
  .more-icon-preocess.process-model li::after {
      top: 24px;
  }
}
@media screen and (max-width: 380px) { 
  .process-model.more-icon-preocess li {
      width: 16%;
  }
  .more-icon-preocess.process-model li span {
      font-size: 16px;
      height: 35px;
      line-height: 32px;
      width: 35px;
  }
  .more-icon-preocess.process-model li p {
      font-size: 8px;
  }
  .more-icon-preocess.process-model li::after {
      top: 18px;
  }
  .process-model.more-icon-preocess {
      text-align: center;
  }
}
  input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], select {
      font-family:"Kanit-Light";
      padding: 0 15px;
  }
  .s_validate{
      color: #e33324;
      font-size: 14px;
      text-align: left;
  }

  .listing-details-primary .property-attr .value-block {
    font-size: 14px;
    line-height: 1.57;
     margin: 5px 20px 10px 0px;
    border-bottom: 1px dashed #cbcdd0;
}
.title-block{
    font-family:Kanit-Light;
}
.label-block
{
    color: #1b1a1a;
    font-family:Kanit-Light;
    font-weight:900;
}
.value-block{
    color: #4c4d4a;
}

.ck-editor__editable {min-height: 400px;}
 
.intro-content-small{
    position: inherit;
    height: 100%;
    width: 100%;
    padding: 10px;
  }
