 .fc-event-main {
    white-space: normal;
}

.fc-daygrid-event{
    white-space: normal;
    background-color: lightgray;
    border-color: gray;
}

.fc .fc-daygrid-event i{
    color: #fff
}

.fc th a{
    color: #fff
}

.fc th .fc-day-sun
,.fc th .fc-day-sat {
    background-color: #e33324
}

.fc th .fc-day-mon
,.fc th .fc-day-tue
,.fc th .fc-day-wed
,.fc th .fc-day-thu
,.fc th .fc-day-fri {
    background-color: #8a8d92
}

.fc .fc-button-primary{
    background-color: #8a8d92;
    border-color: gray;
}

.fc .fc-button-primary:hover{
    background-color: #e33324;
    border-color: gray
}

.fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #e33424;
    border-color: gray;
}

.fc .fc-toolbar-title{
    color: #e33424; 
    font-weight: 600;
} 

i {
    font-style: normal;
    font-size: medium;
    vertical-align: middle;
}
.bullet{
    font-style: normal;
    font-size: 30px;
    vertical-align: middle;
}
.fc-day-today {
    background: #53aaec !important;
    border: none !important;
  
} 