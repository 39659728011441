.leaflet-container {
    height: 400px;
    width: 100%;
}

.h-line{
    padding: 5px 0px 10px 0px;
    margin-bottom: 25px;
    display: block;    
     border-bottom: 1px dashed #868585;
    margin-top: 40px;  
    background-color: none;
    color:#868585;
}

.property-features{
    width:'70%';
    margin: 10px 0px 0px 0px;
}
 