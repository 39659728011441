/* input, input[type=text], input[type=password], input[type=email], input[type=number], textarea, select{
    border-bottom: 1px dashed #5555;
    background-color: #fff;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    box-shadow: none;
    
}
input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], select{
    padding: 0 0px;
} */

.box-profile {
    border: 1px solid #dfd6d6;
    padding: 20px;
    /* box-shadow: 5px 10px 8px #888888; */
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    display: block;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    margin-bottom: 30px;
    position: inherit;
  }