#signinmodal{
    position: absolute;
}

div.text-setting{
    line-height: 1.5;
    color: #212529;
    text-align: left;
    padding: 2rem;
}

div.scroll-wrapper{
    border: 'none';
    border: 1px solid #CED4DA;
    border-radius: 8px;
    
}

.text-color-blue{
    color: #053381;
}

