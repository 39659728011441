.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent; }
    .owl-theme .owl-dots .owl-dot {
      display: inline-block;
      zoom: 1;
      display: inline; }
      .owl-theme .owl-dots .owl-dot span {
        width: 20px;
        height: 20px;
        margin: 5px 7px;
        background: #e7a59f;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px; }
      .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
        background: #e33324; }
    
.owl-nav span {
    color: rgb(88, 85, 85);
	margin: 0px 0px;
	padding: 6px 19px;
	font-size: 24px;
	-webkit-border-radius: 40px;
	-moz-border-radius: 30px;
	border-radius: 40px;
	background: #ffffff;
  border: 1px solid #a6a6a6;
	cursor: pointer;
}

.owl-nav span:hover {
    background-color: #fcc0c0;
  }


.owl-carousel .owl-nav .owl-next{
    right: -30px;
    top: calc(50% - 15px);
    transform: translateY(-90%);
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.owl-carousel .owl-nav .owl-prev{
    left: -30px;
    top: calc(50% - 15px);
    transform: translateY(-90%);
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.owl-carousel .owl-nav button.owl-prev:hover ,
.owl-carousel .owl-nav button.owl-next:hover {
    background-color: hsla(0, 0%, 100%, 0);
}


select {
  padding: 1px 18px;
  cursor: pointer;
}


/* p wut  Custom */

.utf-post-content-area{
  padding: 15px;
}
.widget-tabs li {
  padding: 0 0 10px 0;
  margin: 10px 0 0 0;
}
.utf-post-content-area h3 a {
  color: #333;
  font-family: 'KANIT-LIGHT';
  font-weight: 600;
  font-size: 17px;
} 
.utf-photo-text-content {
z-index: inherit;
}
.inner-map-search-block .utf-main-search-form-item {
margin-top: -150px;
}
.utf-parallax-content-area{
padding: 110px 0;
}
.utf-plan-features {
margin-bottom: 20px;
padding: 0.5px;
}
.utf-plan-features ul li{
padding: 4px;
font-size: 16px;
border-top: none;
}
.utf-plan-features ul li:last-child{
border-bottom:none;
}


.plan.featured .utf-listing-badges-item .featured {
background: #e33324;
  color: #ffffff;
}

.box-img-center{
  position: absolute;
  /* margin-top: 85%; */
  left: 10%;
  color: #fff;
  background: rgba(128, 82, 82, 0.15);
  border-radius: 40px;
  font-size: 12px;
  white-space: nowrap;
  width: 210px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.text-warp{
  white-space: nowrap;
  /* width: 230px;  */
  overflow: hidden;
  text-overflow: ellipsis; 
  width: -webkit-fill-available;
}

.owl-stage{
  margin-bottom: 1%;
}

.utf-listing-user-info{
  color: #909090;
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    border-top: 1px dashed rgb(242, 242, 242);
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  background: none;
    color: inherit;
    border: none;
    padding: 1px !important;
    font: inherit;
}

.compact .utf-listing-img-content-item span{
    color: #fff;
    font-size: 17px;
    font-weight: 100;
}
 
.utf-smt-listing-img-container:after, .utf-smt-listing-img-container:before{
  /* position: inherit; */
  opacity: 0.4;
}

.utf-listing-img-content-item img.utf-user-picture{
  top: -40px;
}

.compact .utf-listing-img-content-item .utf-listing-compact-title-item{
  padding: 10px;
  margin-top:20px;
}


.text-warp-title{
  /* width: 5em;  */
    /* border: 1px solid #000000; */
    word-wrap: break-word;
    /* overflow: hidden; */
    text-overflow: ellipsis; 
    width: -webkit-fill-available;
}


.error{
  background-color: red;
  color: white;
}

 
.banner-section {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto 50px;
}
.banner-section .link-block.main {
  height: 325px;
}
.banner-section .link-block {
  position: relative;
  display: block;
}

.banner-section .link-block.main img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.banner-section .initial-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.banner-section .table {
  padding: 15px;
}

.banner-section .initial-wrapper h3 {
   
  font-size: 22px!important;
  line-height: 28px!important;
  color: #2E3092;
  margin: 0;
  word-break: break-all;
}
#main-wrapper a, #maincontainer-wrapper a, .modal a {
  font-size: 25px;
  line-height: 25px;
}

.banner-section .initial-wrapper .btn-link {
  position: absolute;
  left: 15px;
  bottom: 15px;
}


.banner-section .initial-wrapper .btn-link a {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  background-color: #2E3092;
   font-size: 18px;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}


.flex-col{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.flex-col2{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  align-content: flex-end;
}

@media screen and (min-width: 992px) {
  .col-lg-6 {
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
  }
}

.banner-section .link-block.sub img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.banner-section .link-block img {
  width: 100%;
}
 



.wrapper {
  /* max-width: 940px; */
  max-width:  100%;
  margin: 0 20px;
  display: grid;
  grid-gap: 10px;
}

@media screen and (max-width: 500px) {
  .content {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 500px) {

  /* no grid support? */
  .sidebar {
    /* float: left; */
    width: 100%;
  }

  .image-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .image-column-30 {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 5px;
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
  }
  
  .image-column-70 {
    flex: 0 0 70%;
    max-width: 70%;
    padding: 5px;
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
  }
  
  .image-column-30 img,
  .image-column-70 img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    object-fit: cover; /* Ensures the image covers the entire area */
  }
  
  /* Responsive layout for smaller screens */
  @media (max-width: 600px) {
    .image-column-30, .image-column-70 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .wrapper {
    margin: 0 auto;
    grid-template-columns: 1fr 3fr;
  }
  
  .header, .footer {
    grid-column: 1 / -1;
    /* needed for the floated layout */
    clear: both;
  }

}

.wrapper > * {
  /* background-color: #444; */
  color: #fff;
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
  /* needed for the floated layout*/
  margin-bottom: 10px;
}

/* We need to set the widths used on floated items back to auto, and remove the bottom margin as when we have grid we have gaps. */
@supports (display: grid) {
  .wrapper > * {
    width: auto;
    margin: 0;
  }
}
 
.modal {
  background: green;
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modals.dimmer .ui.scrolling.modal{
  margin: 0rem auto;
}

 
.ui.fullscreen.modal {
  width: 100%!important;
}

button.button-p {
  background-color: rgb(98, 180, 82);
    top: 0;
    padding: 10px 20px;
    color: #fff;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 6px;
    overflow: hidden;
    border: none;
    box-shadow: 0 1px 4px 0px rgb(0 0 0 / 5%);
    border-radius: 4px;
}



.home-icon {
  font-size: 2em;
} 


.box{
  /* background: linear-gradient( 110.3deg,  rgba(73,93,109,1) 4.3%, rgba(49,55,82,1) 96.7% ); */
  background: linear-gradient(#ef8e38,#108dc7);
  font-family: 'Raleway', sans-serif;
  text-align: center;
  border: 1px solid #313752;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease 0s;
}
.box:hover{ box-shadow: 0 0 5px rgba(0,0,0,0.4); }
.box:before{
  content: "";
  width: 100%;
  height: 100%;
  border: 5px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 0 30px #313752;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.35s;
}
.box:hover:before{ opacity: 1; }
.box img{
  width: 100%;
  height: auto;
  opacity: 1.0;
  transition: .3s linear;
}
.box:hover img{
  opacity: 0.3;
  transform: scale(0.9);
}
.box .box-content{
  color: #fff;
  width: 100%;
  transform: translateY(-30%);
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  transition: all .5s ease;
}   
.box:hover .box-content{ transform: translateY(-50%); }
.box .title{
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 3px 0;
  opacity: 0;
  transition: all 0.5s ease 0s;
}
.box .post{
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 10px;
  display: block;
  opacity: 0;
  transition: all 0.5s ease 0s;
}
.box:hover .title,
.box:hover .post{
  opacity: 1;
  transition-delay: 0.2s;
}
.box .icon{
  padding: 0;
  margin: 0;
  list-style: none;
  transform: scale(0);
  transition: all 0.5s ease;
}
.box:hover .icon{ transform: scale(1); }
.box .icon li{
  margin: 0 2px;
  display: inline-block;
}
.box .icon li a{
  color: #313752;
  background-color: #fff;
  font-size: 16px;
  line-height: 35px;
  height: 35px;
  width: 35px;
  display: block;
  transition: all 0.5s ease 0s;
}
.box .icon li a:hover{
  color: #fff;
  background-color: #313752;
  box-shadow: 0 0 5px rgba(0,0,0,0.8);
}
.box .txt{
  font-size: 12px;
  margin-top: 5px;
}
@media only screen and (max-width:990px){
  .box{ margin: 0 0 30px; }
}


.box-detail {
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	background-color: #fff;
	display: inline-block;
	width: 100%;
	border-radius: 3px;
	 
}
.box-detail .txt{
  padding: 5px 10px 5px 10px;
}

.btn-link {
  /* font-weight: 400; */
  color: #007bff;
  background-color: transparent;
  }
  
  .btn-right{
    background-color: rgb(217, 34, 40);
    border: none;
    padding: 3px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    left: 78.6%;
    color:#fff;
  }
  .btn-right:hover{
    color: #e33324;
    background-color: #fff;
    border:1px solid rgb(217, 34, 40);;
  }
  

.utf-post-content-area-top {
    padding: 2px;
}
.title-hot-asset{
  color: #706e6e;
  font-size: 1.2rem;
}
.detail-hot-asset{
  font-size: 1rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ditail-hot-price{
  font-size: 1.4rem;
  color: rgb(65, 62, 62);
}
 
.hover-img img{
width: 100%;
height: 148px;
Object-fit:cover;
}
.hover-img:hover img{
  opacity: 0.7;
}
 
.tabs-nav li a:hover, .tabs-nav li.active a{
color: #09192f;
z-index: 11;
border-bottom: 2px solid #e33324;
border-radius: 4px 4px 0 0;
background: none;
}

.section-hotnew{
  padding: 20px 0px;
}
.section-hotnew .col-md-9 .col-sm-12 {
  padding: 0px;
}
.hot-new-title {
  display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
 

 
/* test */

.grid-con{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(2,250px);
}
.grid-con img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  .grid-item-1{
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  overflow: hidden;
}
.grid-item-2{
  display: grid;
  grid-template-rows: 250px;
  grid-template-columns: repeat(2,1fr);
  grid-column:  3 / 5;
  grid-row: 1 / 2;
  overflow: hidden;
}
.grid-item-3{
  display: grid;
  grid-template-rows: 250px;
  grid-template-columns: repeat(2,1fr);
  grid-column: 3 / 5;
  grid-row:  2 / 3;
  overflow: hidden;
}   
/* .grid-item-4 {
  display: grid;
  grid-template-rows: 330px;
  grid-template-columns: repeat(2,1fr);
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  overflow: hidden;
}
.grid-item-5{
  grid-column: 3 / 5;
  grid-row: 3 / 4 ;
  overflow: hidden;
} */
  .grid-item-info{
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-item-info p {
  margin: 1rem 0rem;
  font-size: 1.6rem;
}
.grid-item-info .black-btn {
  display: inline-block;
  background-color: rgb(93 113 110);
  color:#fff;
  text-decoration: none;
  padding: 0.5rem 3rem;
  border-bottom: 4px solid rgb(30 72 66);
}  
@media (max-width: 820px) {
  .grid-con{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(3,250px);
  }
  .grid-item-1{
    grid-column: 1 / 5;
    grid-row: 1;
    overflow: hidden;
    grid-gap: 2rem;
  }
  .grid-item-2{
    display: grid;
    grid-template-rows: 250px;
    grid-template-columns: repeat(2,1fr);
    grid-column:  1 / 5;
    grid-row: 2 / 2;
    overflow: hidden;
  }
  .grid-item-3{
    display: grid;
    grid-template-rows: 250px;
    grid-template-columns: repeat(2,1fr);
    grid-column: 1 / 5;
    grid-row:  3 / 3;
    overflow: hidden;
  }  
  
  .grid-item-info{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-item-info p {
    margin: 1rem 0rem;
    font-size: 1rem;
    line-height: 2rem;
  }
  .grid-item-info h3{
    display: inline-block;
    margin-top: 0px;
  }
  .grid-item-info .black-btn {
    display: inline-block;
    background-color: rgb(93 113 110);
    color:#fff;
    text-decoration: none;
    padding: 0.5rem 3rem;
    border-bottom: 4px solid rgb(30 72 66);
    font-size: 1.3rem; 
  }
}