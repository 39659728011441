.Boxhead{
    text-align: center;
}
#titlebar{
    margin-bottom: 20px;
}
h2  {
    font-family: Kanit-light;
    font-size: 30px;
    font-weight: 500;
  }
   
.hrstyle{
    margin-left: 100px;
    margin-right: 100px;
    border-bottom: 1px dashed #f2f2f2;
}
.price {
font-size: 35px;
color:#14d364;
font-weight: 500;
text-shadow: 2px 2px 3px rgba(255,255,255,0.1);
 }
.txtproce{
    margin-top: 10px;
}
.imag_chart{
    text-align: center;
}
.txt_tab{
    margin-left: 30px;
}
.txt_tab_bold{
    margin-left: 30px;
    font-weight: 600;
}
.checkboxes.in-row label{
    width: 80%;
}
input:read-only {
    background-color: #f5f5f5;
  }
 
.utf-sidebar-widget-item {
    /* margin-top: 20px; */
    margin-bottom: 10px;
    display: block;
    background: #ffffff;
    padding:0px;
    border-radius: 0px;
    border:none;
    box-shadow:none;
}
 