.utf-sidebar-widget-item {
  padding: 0px;
}
input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
select {
  margin: 5px 0px;
}

table.manage-table .utf-title-container .title h4 a {
  font-family: "Kanit-Light";
  font-size: 16px;
}
table.manage-table .utf-title-container .title span {
  display: block;
  font-family: "Kanit-Light";
  font-size: 14px;
}
select {
  padding: 10px 18px;
  cursor: pointer;
}
.utf-sidebar-widget-item {
  border: none;
  box-shadow: none;
}
.checkbox label {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 25px;
}
.utf-expire-date{
  font-size:13px;
}