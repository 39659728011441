 

  .custom-menu ul li{
    margin: 5px;
}
.login{
    background-color: #ff6e6e;
    border-radius: 5px;
    margin: 5px;
    padding: 0 5px;
}
.signup{
    background-color: #64cbff;
    border-radius: 5px;
    margin: 5px;
    padding: 0 5px;
}
.signup a, .login a{
    color: #fff !important;
}
.bg{
    background-image: url(https://i.postimg.cc/3NDp9bvc/background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 50px;;
}
.count div{
    padding: 20px;
}
.count{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.form{
    margin-top: 20px;;
}
.form input{
    margin-top: 10px;;
    width: 60%;
    margin-left: 20%;
    padding: 5px 15px;
    border-radius: 20px;
    border: none;
    background-color: #c3d7e2;
    outline: none;
}
.form button{
    width: 30%;
    border: none;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #dd4c54;
    color: #fff;
    outline: none;
    cursor: pointer;
}