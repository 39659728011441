input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], select {
    margin: 5px 0px;
  }
  .tab-content {
  padding: 0px 0px;
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 100%;
}
.utf-sidebar-widget-item {
  padding: 0px;
  margin-bottom: 0px;
  display: block;
  background: #ffffff;
  padding: 0px;
  border-radius: 6px;
  border: 0px;
  box-shadow: none;
}
.h5, h5 {
  margin-top: 18px;
  margin-bottom: 10px;
  color: #846d6d;
  font-family: Kanit-Light;
}
.nav>li>a:focus, .nav>li>a:hover{background: transparent!important;}
.design-process-section .text-align-center {
  line-height: 25px;
  margin-bottom: 12px;
}
.design-process-content {
  border: 1px solid #e9e9e9;
  position: relative;
  padding: 16px 34% 30px 30px;
}
.design-process-content img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  max-height: 100%;
}
.design-process-content h3 {
  margin-bottom: 16px;
}
.design-process-content p {
  line-height: 26px;
  margin-bottom: 12px;
}
.process-model {
  list-style: none;
  padding: 0;
  position: relative;
  max-width: 600px;
  margin: 20px auto 26px;
  border: none;
  z-index: 0;
}
.process-model li::after {
  background: #e5e5e5 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  margin: 0 auto;
  position: absolute;
  right: -30px;
  top: 33px;
  width: 85%;
  z-index: -1;
}
.process-model li.visited::after {
  background: #D6DA28;
}
.process-model li:last-child::after {
  width: 0;
}
.process-model li {
  display: inline-block;
  width: 18%;
  text-align: center;
  float: none;
}
.nav-tabs.process-model > li.active > a, .nav-tabs.process-model > li.active > a:hover, .nav-tabs.process-model > li.active > a:focus, .process-model li a:hover, .process-model li a:focus {
  border: none;
  background: transparent;

}
.process-model li a {
  padding: 0;
  border: none;
  color: #606060;
}
.process-model li.active,
.process-model li.visited {
  color: #e33324;
}
.process-model li.active a,
.process-model li.active a:hover,
.process-model li.active a:focus,
.process-model li.visited a,
.process-model li.visited a:hover,
.process-model li.visited a:focus {
  color: #e33324;
}
.process-model li.active p,
.process-model li.visited p {
  font-weight: 100;
  color: #e33324;
  font-family: Kanit-Light;
}
.process-model li i {
  display: block;
  height: 68px;
  width: 68px;
  text-align: center;
  margin: 0 auto;
  background: #f5f6f7;
  border: 2px solid #e5e5e5;
  line-height: 65px;
  font-size: 30px;
  border-radius: 50%;
}
.process-model li.active i, .process-model li.visited i  {
  background: #fff;
  border-color: #e33324;
}
.process-model li p {
  font-family: Kanit-light;
  font-size: 14px;
  margin-top: 11px;
}
.process-model.contact-us-tab li.visited a, .process-model.contact-us-tab li.visited p {
  color: #606060!important;
  font-weight: normal
}
.process-model.contact-us-tab li::after  {
  display: none; 
}
.process-model.contact-us-tab li.visited i {
  border-color: #e5e5e5; 
}

.header-inbox{
  font-family:Kanit-light;padding: 1px 0px 10px 0px;margin: 20px 20px 10px 14px;border-bottom: 1px dashed #cbcdd0;
  font-weight: 500;
}

.checkboxes.in-row label {
font-weight: 100;
}
@media screen and (max-width: 560px) {
.more-icon-preocess.process-model li span {
      font-size: 23px;
      height: 50px;
      line-height: 46px;
      width: 50px;
  }
  .more-icon-preocess.process-model li::after {
      top: 24px;
  }
}
@media screen and (max-width: 380px) { 
  .process-model.more-icon-preocess li {
      width: 16%;
  }
  .more-icon-preocess.process-model li span {
      font-size: 16px;
      height: 35px;
      line-height: 32px;
      width: 35px;
  }
  .more-icon-preocess.process-model li p {
      font-size: 8px;
  }
  .more-icon-preocess.process-model li::after {
      top: 18px;
  }
  .process-model.more-icon-preocess {
      text-align: center;
  }
}
  input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], select {
      font-family:"Kanit-Light";
      padding: 0 15px;
  }
  .s_validate{
      color: #e33324;
      font-size: 14px;
      text-align: left;
  }

  .listing-details-primary .property-attr .value-block {
    font-size: 14px;
    line-height: 1.57;
     margin: 5px 20px 10px 0px;
    border-bottom: 1px dashed #cbcdd0;
}
.title-block{
    font-family:Kanit-Light;
}
.label-block
{
    color: #1b1a1a;
    font-family:Kanit-Light;
    font-weight:900;
}
.value-block{
    color: #4c4d4a;
}

.ck-editor__editable {min-height: 400px;}