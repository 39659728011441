/* .button-icon {
    background-color: rgba(255, 255, 255, 0.149);
    border-radius: 4px;
    height: 46px;
    padding: 0 18px;
    text-align: center;
    width: auto;
	margin-left:3px;
	margin-right:3px;
	margin-bottom:5px;
	display:inline-block;
	transition: 0.3s;
} */

.search-container .row.with-forms .chosen-container {
	margin-bottom: 0
}
.search-container .chosen-container-single .chosen-single {
	height: 45px;
	line-height: 45px;
	font-size: 16px;
	border-color: #fff !important
}
.search-container .chosen-container .chosen-drop {
	border-color: #fff
}

.row.with-forms input, .row.with-forms input[type="text"], .row.with-forms input[type="password"], .row.with-forms input[type="email"], .row.with-forms input[type="number"], .row.with-forms textarea, .row.with-forms input, .row.with-forms select, .row.with-forms .chosen-container {
	margin-bottom: 18px;
}

.submit-page .chosen-container.chosen-container-single{
	margin-bottom:15px;
}

/* ---------------------------------- */
/* Chosen Plugin
------------------------------------- */
.chosen-container {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	font-size: 15px;
	zoom: 1;
 *display: inline;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.chosen-container * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.chosen-container .chosen-drop {
	position: absolute;
	top: 100%;
	left: -9999px;
	z-index: 990;
	width: 100%;
	background-color: #fbfdff;
    border: 1px solid #dde6ef;
	background: linear-gradient(to bottom, rgba(251,253,255,1) 0%, rgba(251,253,255,1) 100%);
	border-top: 0;
	border-radius: 0 0 3px 3px;
}
.chosen-container.chosen-with-drop .chosen-drop {
	left: 0;
}
.chosen-container a {
	cursor: pointer;
}
.chosen-container-single .chosen-single {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0 0 0 18px;
	height: 45px;
	line-height: 45px;
	background-color: #fbfdff;
    border: 1px solid #dde6ef;
	box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
	color: #888;
	text-decoration: none;
	white-space: nowrap;
	font-weight: 500;
	border-radius: 3px;
}
.chosen-container-single .chosen-default {
	color: #999;
}
.chosen-container-single .chosen-single span {
	display: block;
	overflow: hidden;
	margin-right: 26px;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #888;
}
.chosen-container-single .chosen-single-with-deselect span {
	margin-right: 38px;
}
.chosen-container-single .chosen-single div {
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	width: 18px;
	height: 100%;
}
.chosen-container-single .chosen-single div b {
	display: block;
	position: relative;
}
.chosen-container-single .chosen-single div b:after {
	content: "\f0d7";
	font-family: "FontAwesome";
	font-size: 14px;
	margin: 0 14px 0 0;
	top: -1px;
	position: relative;
	width: 11px;
	height: 5px;
	display: inline-block;
	color: #c0c0c0;
	float: right;
	font-weight: normal;
}
.chosen-container-single .chosen-search {
	position: relative;
	z-index: 1010;
	margin: 0;
	padding: 3px 8px;
	white-space: nowrap;
}
.chosen-container-single .chosen-search input[type="text"] {
	margin: 1px 0;
	padding: 6px 20px 6px 6px;
	width: 100%;
	height: 40px;
	outline: 0;
	border: 1px solid #ddd;
	background-color: #fff;
	display: block;
	position: relative;
	z-index: 9;
	background-color: #fafafa;
}
.chosen-container-single .chosen-search:before {
	content: "\f002";
	font-family: "FontAwesome";
	font-size: 15px;
	margin: 0 15px 0 0;
	top: 12px;
	position: relative;
	width: 11px;
	height: 5px;
	display: inline-block;
	color: #aaa;
	float: right;
	font-weight: normal;
	z-index: 10;
}
.chosen-container-single .chosen-drop {
	margin-top: -1px;
	background-clip: padding-box;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
	position: absolute;
	left: -9999px;
}
.utf-sort-box-aera {
    display: inline-block;
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    padding: 12px 15px 2px 15px;
    position: relative;
    line-height: 28px;
    margin-bottom:20px;
}
.sort-by {
	position: relative;
	top: 0px;
	float:left;
}
.sort-by label, .sort-by .sort-by-select {
	display: inline-block;
	float: left;
}
.sort-by label {
	line-height: 40px;
	margin-right: 8px;
	padding: 0;
	margin-bottom:0;
}
.sort-by .chosen-container-active .chosen-single, .sort-by .chosen-container-active .chosen-choices, .sort-by .chosen-container-active.chosen-with-drop .chosen-single, .sort-by .chosen-container-single .chosen-single {
	height: 40px;
	line-height: 40px;
	border: 1px solid #f2f2f2;
	background-color: #fff;
	width: 100%;
	display: inline-block;
	padding-left: 10px;
}
.sort-by .chosen-container-active .chosen-drop, .sort-by .chosen-container-single .chosen-drop {
	opacity: 0;
	transition: opacity 0.3s;
}
.sort-by .chosen-container-active.chosen-with-drop .chosen-drop {
	opacity: 1;
}
.sort-by .chosen-container .chosen-drop {
	padding-top: 10px;
	border: 1px solid #e0e0e0;
	border-top: 1px solid #e0e0e0;
	border-radius: 3px;
	width: 200px;
	margin-left: 0px;
	background: #fff;
	box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
	z-index: 999;
}
.sort-by .chosen-container-single .chosen-single div b:after {
	color: #e33324;
}
.sort-by .chosen-container-single .chosen-default {
	color: #e33324;
}
.sort-by .chosen-container-single .chosen-single span {
	margin-right: 0px;
	color: #707070;
	font-weight:500;
	vertical-align: top;
}
.sort-by .chosen-container-single .chosen-single div {
	position: relative;
	top: -1px;
	margin-left: 15px;
	display: inline-block;
}
.chosen-container-single .chosen-single span {
	display: inline-block;
	margin-right: 0;
}
.chosen-container .chosen-results {
	color: #888;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0 4px 8px 0;
	padding: 0 4px 0 8px;
	max-height: 300px;
}
.chosen-container .chosen-results li {
	display: none;
	margin: 0;
	/*border-top:1px solid #dde6ef;*/
	padding: 10px 10px;
	list-style: none;
	line-height: 15px;
	word-wrap: break-word;
	-webkit-touch-callout: none;
}
.chosen-container-multi .chosen-results li {
	padding: 10px 18px;
}
.chosen-container .chosen-results li.active-result {
	display: list-item;
	cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
	display: list-item;
	color: #ccc;
	cursor: default;
}
.chosen-container .chosen-results li.highlighted {
	background-color: #e33324;
	border-radius: 3px;
	color: #fff;
	transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.chosen-container .chosen-results li.no-results {
	color: #888;
	display: list-item;
	background-color: #fff;
}
.chosen-container-multi .chosen-results li.no-results {
	background-color: #fff;
}
.chosen-container .chosen-results li.group-result {
	display: list-item;
	font-weight: bold;
	cursor: default;
}
.chosen-container .chosen-results li.group-option {
	padding-left: 15px;
}
.chosen-container .chosen-results li em {
	font-style: normal;
	text-decoration: underline;
}
.chosen-container-multi .chosen-choices {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0 0 0 18px;
	height: 53px;
	border: 1px solid #e0e0e0;
	background-color: #fcfcfc;
	text-decoration: none;
	white-space: nowrap;
	line-height: 33px;
	cursor: pointer;
	margin: 0;
}
.chosen-container-multi .chosen-choices li {
	float: left;
	list-style: none;
}
.chosen-container-multi .chosen-choices li.search-field {
	margin: 0;
	padding: 0;
	white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
	margin: 0;
	padding: 0;
	height: 53px;
	outline: 0;
	border: 0 !important;
	background: transparent !important;
	color: #888;
	line-height: normal;
	font-weight: 500;
}
.chosen-container-multi .chosen-choices li.search-choice {
	position: relative;
	margin: 12px 5px 0 -4px;
	padding: 0 25px 0 8px;
	max-width: 100%;
	background-color: #e33324;
	line-height: 29px;
	color: #fff;
	cursor: default;
	font-weight: 500;
}
.chosen-container-multi .chosen-choices li.search-choice span {
	word-wrap: break-word;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
	position: absolute;
	top: -2px;
	right: 7px;
	display: block;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
	content: "\f00d";
	font-family: "FontAwesome";
	font-size: 13px;
	top: 1px;
	position: relative;
	width: 11px;
	height: 5px;
	display: inline-block;
	color: #fff;
	float: right;
	font-weight: normal;
}
.chosen-container-multi .chosen-choices li.search-choice-disabled {
	padding-right: 5px;
	border: 1px solid #ccc;
	background-color: #e4e4e4;
	color: #888;
}
.chosen-container-multi .chosen-choices li.search-choice-focus {
	background: #d4d4d4;
}
.chosen-container-multi .chosen-results {
	margin: 0;
	padding: 0;
}
.chosen-container-multi .chosen-drop .result-selected {
	display: list-item;
	color: #ccc;
	cursor: default;
}
.chosen-container-active .chosen-single, .chosen-container-active .chosen-choices, .chosen-container-active.chosen-with-drop .chosen-single {
	border: 1px solid #e0e0e0;
}
.chosen-container-active.chosen-with-drop .chosen-single div {
	border-left: none;
	background: transparent;
}
.chosen-container-active.chosen-with-drop .chosen-single div b:after {
	content: "\f0d8"
}
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
	color: #888 !important;
}
.chosen-drop ::-webkit-scrollbar {
width: 10px;
}
 .chosen-drop ::-webkit-scrollbar-track {
 background-color: rgba(0,0,0,0.05);
 border-radius: 10px;
}
 .chosen-drop ::-webkit-scrollbar-thumb {
 border-radius: 10px;
 background-color: rgba(0,0,0,0.2);
}
 .chosen-drop ::-webkit-scrollbar-thumb:hover {
 border-radius: 10px;
 background-color: rgba(0,0,0,0.3);
}



/* เพ่ิมใหม่ */
