.utf-icon-box-item-area{
    margin-bottom: 30px;
    padding: 10px 10px;
}
.utf-icon-box-item-area:hover{
 background-color:rgb(197, 190, 190);
 color:#fff;
}
.blog-img-new{
    width: 100%;
    height: 100%;
}