#utf-compare-list li div {
    flex: 1;
    border-right: 1px solid transparent;
    padding: 10px 0px;
    color: #777;
    text-align: center;
    border-right: 1px dashed;
    border-bottom: 1px dashed;
}
#utf-compare-list li {
    border-bottom: 1px solid transparent;
    font-size: 15px;
}
.txt-left{
    text-align: left;
}
th{
    text-align: center;
    vertical-align: middle;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    vertical-align: middle;
    font-size: 15px;
}

 
  
  /* try removing the "hack" below to see how the table overflows the .body */
  .hack1 {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  
  .hack2 {
    display: table-cell;
    overflow-x: auto;
    width: 100%;
  }
  .sl-icon{  
    background-color: beige;
    padding: 20px;
    border-radius: 30px;
   }